import { IResultFilterSocialNetworks } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';

export const getSocialNetworksPublic =
	async (): Promise<IResultFilterSocialNetworks> => {
		'use client';
		try {
			const headers = await commonRequestHeaders();
			const promise = await fetch(
				`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/socialNetworks/public/socialNetworksPublic`,
				{
					method: 'GET',
					credentials: 'include',
					headers: headers,
				}
			);
			if (!promise.ok) {
				if (promise.status === 401) {
					return { status: 401, docs: [], total: 0 };
				}
			}

			const { data, result, message } = await promise.json();
			if (result === 2) {
				throw new Error(message);
			}

			return {
				status: 200,
				docs: data,
				total: data.length || 0,
			};
		} catch (error) {
			throw new Error((error as Error).message);
		}
	};
